<template>
  <!--
    The view for the MediaReportChart-components.
    Used to display information about the installations media (skimovie, phototrap, etc.).
  -->
  <div class="installationDetail_Reports">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <template v-if="spotIds.length > 0">
        <div
          id="nav-tab"
          class="nav nav-tabs"
          role="tablist"
        >
          <a
            v-for="(key, index) in spotIds"
            :id="`spoT${ index }-tab`"
            :key="`Report-tab-listItem-${ index }`"
            :class="['nav-item nav-link', {'active': index == 0}]"
            data-toggle="tab"
            :href="`#spoT${ index }`"
            role="tab"
            :aria-controls="`spoT${ index }`"
            :aria-selected="index === 0"
          >Spot: {{ key }}</a>
        </div>
        <div
          id="nav-tabContent"
          class="tab-content"
        >
          <div
            v-for="(spotId, index) in spotIds"
            :id="`spoT${ index }`"
            :key="`Report-spot-listItem-${ index }`"
            :class="['reports-pane tab-pane fade show', {'active': index == 0}]"
            role="tabpanel"
            :aria-labelledby="`spoT${ index }-tab`"
          >
            <div
              v-if="showMediaReportChart"
              class="card"
            >
              <MediaReportChart
                :installation-id="installationId"
                :spot-id="spotId"
              />
            </div>
            <div
              v-if="showMediaErrorReportChart"
              class="card"
            >
              <Portlet
                :title="$t('installationDetailReportsView.detailedReports')"
                :sub-title="$t('installationDetailReportsView.lastSevenDays')"
                icon="chart-area"
              >
                <MediaReportDetailChart
                  :installation-id="installationId"
                  :spot-id="spotId"
                >
                  <MediaReportDetailChartPhoto />
                </MediaReportDetailChart>
              </Portlet>
            </div>

            <div
              v-if="showMediaDetailReportChart"
              class="card"
            >
              <Portlet
                :title="$t('installationDetailReportsView.detailedReports')"
                :sub-title="$t('installationDetailReportsView.lastSevenDays')"
                icon="chart-area"
              >
                <MediaReportDetailChart 
                  :installation-id="installationId"
                  :spot-id="spotId"
                  :is-movie="true"
                >
                  <MediaReportDetailChartMovie />
                </MediaReportDetailChart>
              </Portlet>
            </div>
          </div>
        </div>
      </template>
      <template
        v-else-if="isPhotopoint"
      >
        <div
          class="card"
        >
          <MediaReportChart
            :installation-id="installationId"
            :spot-id="-999"
          />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "InstallationDetailReports",
  components: {
    MediaReportChart: () => import('@/components/Media/MediaReport/MediaReportChart.vue'),
    MediaReportDetailChart: () => import('@/components/Media/MediaReport/MediaReportDetailChart.vue'),
    MediaReportDetailChartPhoto: () => import('@/components/Media/MediaReport/MediaReportDetailChartPhoto.vue'),
    MediaReportDetailChartMovie: () => import('@/components/Media/MediaReport/MediaReportDetailChartMovie.vue'),
  },
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      installationObject: null,
      loading: true,
      spotIds: [],
    }
  },
  metaInfo () {
    return {
      title: this.$t('installationDetailReportsView.reports')
    }
  },
  computed: {
    isPhotopoint () {
      if (!this.installationObject.installationType) {
        return false;
      }
      let type = this.installationObject.installationType.trim().toLowerCase();
      let allowedTypes = [ 'photopoint' ];
      return allowedTypes.includes(type);
    },
    showMediaReportChart () {
      if (!this.installationObject.installationType) {
        return false;
      }
      let type = this.installationObject.installationType.trim().toLowerCase();
      let allowedTypes = [ 'photostart', 'skimovie', 'biketrack', 'speedcheck', 'phototrap' ];
      return allowedTypes.includes(type);
    },
    showMediaDetailReportChart () {
      if (!this.installationObject.installationType) {
        return false;
      }
      let type = this.installationObject.installationType.trim().toLowerCase();
      let allowedTypes = [ 'skimovie', 'biketrack' ];
      return allowedTypes.includes(type);
    },
    showMediaErrorReportChart () {
      if (!this.installationObject.installationType) {
        return false;
      }
      let type = this.installationObject.installationType.trim().toLowerCase();
      let allowedTypes = [ 'speedcheck' ];
      return allowedTypes.includes(type);
    }
  },
  created () {
    this.getInstallation();
  },
  methods: {
    getInstallation () {
      this.loading = true;
      this.axios.get('/Installation/Get?id=' + this.installationId)
        .then((response) => {
          this.installationObject = response.data;
          if(!this.isPhotopoint) {
            this.getInstallationSpotIds();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
      getInstallationSpotIds () {
      this.loading = true;
      this.axios.get('/Media/GetSpotIds/' + this.installationId)
        .then((response) => {
          this.spotIds = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style scoped>
.reports-pane:not(.active) {
  display: block;
  height: 0;
  overflow-y: hidden;
}
</style>